.socialLinks {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__iconbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:space-between;
        align-items: space-between;
    }

    &__Link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        height: 90%;
        width: 90%;
        &:hover{
            cursor:pointer;
            height: 100%;
            width: 100%;
        }
    }
}