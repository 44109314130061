@import "../../Styling/Partials/edgespace";
@import "../../Styling/Partials/fontsize";
@import "../../Styling/Partials/media";

.nav {
    width: 40%;
    max-width: 250px;

    @include laptop {
        min-width: 650px;
        max-width: 750px;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap:wrap;
        padding: 0;
        margin: 0;

        @include laptop {
            flex-direction: row;
        }
    }

    &__li {
        display: flex;
        justify-content: center;
        list-style: none;
        @include fontsizeHeader;
        &:hover {
            cursor:pointer;
        }
    }
    
}