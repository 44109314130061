@import "../../Styling/Partials/media";
@import "../../Styling/Partials/fontsize";

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    &__SiteTitle {
        @include fontsizeTitle;
    }
}