@import "../../Styling/Partials/fontsize";

.events {
    display: flex;
    align-items: center;
    
    &__Title {
        @include fontsizeSubtitle;
    }

    &__Content {
        @include fontsize;
    }
}