@import "./media";

@mixin fontsize {
    @include phone {
        font-size: 1rem;
    }
    @include tablet {
        font-size: 1.1rem;
    }
    @include laptop {
        font-size: 1.2rem;
    }
    @include desktop {
        font-size: 1.5rem;
    }
}

@mixin fontsizeLabel {
    @include phone {
        font-size: 0.6rem;
    }
    @include tablet {
        font-size: 0.8rem;
    }
    @include laptop {
        font-size: 1rem;
    }
    @include desktop {
        font-size: 1rem;
    }
}

@mixin fontsizeHeader {
    @include phone {
        font-size: 1.1rem;
    }
    @include tablet {
        font-size: 1.3rem;
    }
    @include laptop {
        font-size: 1.5rem;
    }
    @include desktop {
        font-size: 2rem;
    }
}

@mixin fontsizeSubtitle {
    @include phone {
        font-size: 1.3rem;
    }
    @include tablet {
        font-size: 1.5rem;
    }
    @include laptop {
        font-size: 2rem;
    }
    @include desktop {
        font-size: 2.5rem;
    }
}

@mixin fontsizeTitle {
    @include phone {
        font-size: 1.5rem;
    }
    @include tablet {
        font-size: 1.75rem;
    }
    @include laptop {
        font-size: 2.5rem;
    }
    @include desktop {
        font-size: 4rem;
    }
}