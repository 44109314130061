$phone-width: 480px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1600px;

@mixin phone {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
      @content;
    }
}