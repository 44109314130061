@import "../../Styling/Partials/edgespace";
@import "../../Styling/Partials/fontsize";
@import "../../Styling/Partials/media";

.contact {

    &__Header {
        @include fontsizeHeader;
    }
    
    &__socialList {
        text-decoration: none;
        list-style-type: none;
        padding: 0;
        @include fontsize;
    }

    &__socialListItem {
        margin: 0;
        text-decoration: none;
    }

    &__Textarea {
        width: 250px;
        height: 100px;
        resize: none;

        @include laptop {
            width: 500px;
            height: 200px;
        }

        @include desktop {
            width: 500px;
            height: 200px;
        }
    }

    &__sendLink {
        text-decoration: none;
    }

    &__SendButton {
        width: 200px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        
        color: white;
        background-color: black;
        padding: 10px;
        font-weight: 600;
        @include fontsizeHeader;
        margin: 10px;

        &:hover {
            filter: opacity(0.8);
        }
    }

    &__Content {
        @include fontsize;
        max-width: 250px;

        @include laptop {
            max-width: 350px;
        }

        @include desktop {
            max-width: 450px;
        }
    }
}