@import "../../Styling/Partials/media";
@import "../../Styling/Partials/fontsize";

.about-card {
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 300px auto;
    grid-template-areas: "image"
    "content";
    justify-content: center;

    @include tablet {
        grid-template-columns: 100px 400px 100px;
        grid-template-rows: 400px auto;
        grid-template-areas: ". image ."
        "content content content";
    }

    @include laptop {
        grid-template-columns: 400px 400px;
        grid-template-rows: 500px;
        grid-template-areas: "image content";
    }

    @include desktop {
        grid-template-columns: 600px 600px;
        grid-template-rows: 600px;
        grid-template-areas: "image content";
    }

    &__content{
        grid-area: content;
        @include fontsize;
    }
}

.aboutHeader {
    @include fontsizeSubtitle;
}

.aboutImgBox {
    height: 300px;
    width: 300px;
    grid-area: image;
    border-radius: 50%;
    overflow: hidden;

    @include tablet {
        height: 400px;
        width: 400px;
    }

    @include laptop {
        height: 350px;
        width: 350px;
    }

    @include desktop {
        height: 550px;
        width: 550px;
    }

    &__image{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}