@import "../../Styling/Partials/fontsize";

.eventinfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @include fontsize;

    &__field {
        margin: 0;
    }
}