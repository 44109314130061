@import "../../Styling/Partials/edgespace";

.footer {
    height: 120px;
    width: 100%;
    margin: 50px 0 20px 0;
    
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    &__email {
        font-size: 1.5em;
    }
}