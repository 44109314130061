//Custom stuff I added:
.ml-block-form {
    max-width: 400px;
}

.ml-form-embedBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

//From the MailerLite html embed code (some small tweaks):

.ml-form-embedSubmitLoad {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.ml-form-embedSubmitLoad:after {
    content: " ";
    display: block;
    width: 11px;
    height: 11px;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #ffffff #ffffff #ffffff transparent;
    animation: ml-form-embedSubmitLoad 1.2s linear infinite;
}

@keyframes ml-form-embedSubmitLoad {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#mlb2-15531971.ml-form-embedContainer {
    box-sizing: border-box;
    display: table;
    margin: 0 auto;
    position: static;
    width: 100% !important;
}

#mlb2-15531971.ml-form-embedContainer h4,
#mlb2-15531971.ml-form-embedContainer p,
#mlb2-15531971.ml-form-embedContainer span,
#mlb2-15531971.ml-form-embedContainer button {
    text-transform: none !important;
    letter-spacing: normal !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper {
    background-color: transparent;

    border-width: 0px;
    border-color: transparent;
    border-radius: 4px;
    border-style: solid;
    box-sizing: border-box;
    display: inline-block !important;
    margin: 0;
    padding: 0;
    position: relative;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper.embedPopup,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper.embedDefault {
    width: 400px;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
    max-width: 800px;
    width: 100%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-align-left {
    text-align: left;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-align-center {
    text-align: center;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-align-default {
    display: table-cell !important;
    vertical-align: middle !important;
    text-align: center !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-align-right {
    text-align: right;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedHeader img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: auto;
    margin: 0 auto !important;
    max-width: 100%;
    width: undefinedpx;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody.ml-form-embedBodyHorizontal {
    padding-bottom: 0;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody form {
    margin: 0;
    width: 100%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow {
    margin: 0 0 20px 0;
    width: 100%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow {
    float: left;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent.horozintalForm {
    margin: 0;
    padding: 0 0 20px 0;
    width: 100%;
    height: auto;
    float: left;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow {
    margin: 0 0 10px 0;
    width: 100%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow.ml-last-item {
    margin: 0;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow.ml-formfieldHorizintal {
    margin: 0;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input {
    background-color: #ffffff !important;
    color: #333333 !important;
    border-color: #cccccc;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    height: auto;
    line-height: 21px !important;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 10px 10px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input::-webkit-input-placeholder,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input::-webkit-input-placeholder {
    color: #333333;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input::-moz-placeholder,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input::-moz-placeholder {
    color: #333333;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input:-ms-input-placeholder,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input:-ms-input-placeholder {
    color: #333333;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input:-moz-placeholder,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input:-moz-placeholder {
    color: #333333;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow textarea,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow textarea {
    background-color: #ffffff !important;
    color: #333333 !important;
    border-color: #cccccc;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    height: auto;
    line-height: 21px !important;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::before {
    border-color: #cccccc !important;
    background-color: #ffffff !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow input.custom-control-input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-top: 5px;
    margin-left: -1.5rem;
    overflow: visible;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::before {
    border-radius: 4px !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-input:checked~.custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-input:checked~.custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox input[type=checkbox]:checked~.label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox input[type=checkbox]:checked~.label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow input[type=checkbox]:checked~.label-description::before {
    border-color: #000000 !important;
    background-color: #000000 !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-label::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-label::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-label::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-label::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-label::after {
    top: 2px;
    box-sizing: border-box;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::after {
    top: 0px !important;
    box-sizing: border-box !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::after {
    top: 0px !important;
    box-sizing: border-box !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::after {
    top: 0px !important;
    box-sizing: border-box !important;
    position: absolute;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::before {
    top: 0px !important;
    box-sizing: border-box !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .custom-control-label::before {
    position: absolute;
    top: 4px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px;
    border-radius: 50%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .custom-control-label::after {
    position: absolute;
    top: 2px !important;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::before,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::before {
    position: absolute;
    top: 4px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px;
    border-radius: 50%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::after {
    position: absolute;
    top: 0px !important;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::after {
    position: absolute;
    top: 0px !important;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .custom-radio .custom-control-label::after {
    background: no-repeat 50%/50% 50%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .custom-checkbox .custom-control-label::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedPermissions .ml-form-embedPermissionsOptionsCheckbox .label-description::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-interestGroupsRow .ml-form-interestGroupsRowCheckbox .label-description::after,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description::after {
    background: no-repeat 50%/50% 50%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-control,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-input,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-input,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-input,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-sizing: border-box;
    padding: 0;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-radio .custom-control-label,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-radio .custom-control-label,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-checkbox .custom-control-label,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-checkbox .custom-control-label {
    color: #000000;
    font-size: 12px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    line-height: 22px;
    margin-bottom: 0;
    position: relative;
    vertical-align: top;
    font-style: normal;
    font-weight: 700;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-fieldRow .custom-select,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow .custom-select {
    background-color: #ffffff !important;
    color: #333333 !important;
    border-color: #cccccc;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 28px 10px 12px !important;
    width: 100% !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    background: url('https://assets.mlcdn.com/ml/images/default/dropdown.svg') no-repeat right .75rem center/8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow {
    height: auto;
    width: 100%;
    float: left;
}

.ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal {
    width: 70%;
    float: left;
}

.ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-button-horizontal {
    width: 30%;
    float: left;
}

.ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-button-horizontal.labelsOn {
    padding-top: 25px;
}

.ml-form-formContent.horozintalForm .ml-form-horizontalRow .horizontal-fields {
    box-sizing: border-box;
    float: left;
    padding-right: 10px;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow input {
    background-color: #ffffff;
    color: #333333;
    border-color: #cccccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: initial;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow button {
    background-color: #000000 !important;
    border-color: #000000;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    box-shadow: none;
    color: #ffffff !important;
    cursor: pointer;
    font-family: 'Merriweather', serif;
    font-size: 18px !important;
    font-weight: 700;
    line-height: 20px;
    margin: 0 !important;
    padding: 10px !important;
    width: 100%;
    height: auto;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-horizontalRow button:hover {
    background-color: #393939 !important;
    border-color: #393939 !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin-top: 5px;
    margin-left: -1.5rem;
    overflow: visible;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow .label-description {
    color: #000000;
    display: block;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    position: relative;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow label {
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    min-height: 24px;
    padding-left: 24px;

}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow label a {
    color: #000000;
    text-decoration: underline;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow label p {
    color: #000000 !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
    font-size: 12px !important;
    font-weight: normal !important;
    line-height: 18px !important;
    padding: 0 !important;
    margin: 0 5px 0 0 !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow label p:last-child {
    margin: 0;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit {
    margin: 0 0 20px 0;
    float: left;
    width: 100%;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit button {
    background-color: #000000 !important;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    color: #ffffff !important;
    cursor: pointer;
    font-family: 'Merriweather', serif !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    height: auto;
    padding: 10px !important;
    width: 100% !important;
    box-sizing: border-box !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit button.loading {
    display: none;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit button:hover {
    background-color: #393939 !important;
}

.ml-subscribe-close {
    width: 30px;
    height: 30px;
    background: url('https://assets.mlcdn.com/ml/images/default/modal_close.png') no-repeat;
    background-size: 30px;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -10px;
    position: absolute;
    top: 0;
    right: 0;
}

.ml-error input,
.ml-error textarea,
.ml-error select {
    border-color: red !important;
}

.ml-error .custom-checkbox-radio-list {
    border: 1px solid red !important;
    border-radius: 4px;
    padding: 10px;
}

.ml-error .label-description,
.ml-error .label-description p,
.ml-error .label-description p a,
.ml-error label:first-child {
    color: #ff0000 !important;
}

#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow.ml-error .label-description p,
#mlb2-15531971.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow.ml-error .label-description p:first-letter {
    color: #ff0000 !important;
}

@media only screen and (max-width: 400px) {

    .ml-form-embedWrapper.embedDefault,
    .ml-form-embedWrapper.embedPopup {
        width: 100% !important;
    }

    .ml-form-formContent.horozintalForm {
        float: left !important;
    }

    .ml-form-formContent.horozintalForm .ml-form-horizontalRow {
        height: auto !important;
        width: 100% !important;
        float: left !important;
    }

    .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal {
        width: 100% !important;
    }

    .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal>div {
        padding-right: 0px !important;
        padding-bottom: 10px;
    }

    .ml-form-formContent.horozintalForm .ml-button-horizontal {
        width: 100% !important;
    }

    .ml-form-formContent.horozintalForm .ml-button-horizontal.labelsOn {
        padding-top: 0px !important;
    }

}