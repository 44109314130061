@import "./Partials/edgespace";

section {
    display:flex;
    flex-direction: column;
    align-items: center;

    @include edgespace;
}

.spacer {
    flex:1;
}