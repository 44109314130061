@import "./media";

@mixin edgespace {
    @include phone {
        margin: 0 50px;
    }
    @include tablet {
        margin: 0 75px;
    }
    @include laptop {
        margin: 0 100px;
    }
    @include desktop {
        margin: 0 200px;
    }
}