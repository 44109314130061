@import "../../Styling/Partials/media";
@import "../../Styling/Partials/fontsize";

.poets {

    &__projectPage {
        display: grid;
        grid-template-columns: 250px;
        grid-template-rows: 320px 60px 380px 230px auto;
        grid-template-areas: "image1" "title" "content" "award";
        justify-content: center;
    
        @include tablet {
            grid-template-columns: 350px;
            grid-template-rows: 440px 60px 330px 250px auto;
        }
    
        @include laptop {
            margin-top: 30px;
            grid-template-columns: 475px 325px;
            grid-template-rows: 100px 500px 300px auto;
            grid-template-areas: "image1 title" "image1 content" "award award";
        }
    
        @include desktop {
            margin-top: 30px;
            grid-template-columns: 700px 500px;
            grid-template-rows: 100px 775px 350px auto;
            grid-template-areas: "image1 title" "image1 content" "award award";
        }
    }

    &__image {
        width: 250px;
        object-fit: cover;
    
        @include tablet {
            width: 350px;
        }
    
        @include laptop {
            width: 475px;
        }
    
        @include desktop {
            width: 700px;
            object-fit: contain;
        }
    }

    &__imgBox {
        width: 250px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-self: center;
    
        @include tablet {
            width: 350px;
        }
    
        @include laptop {
            width: 475px;
            height: 100%;
        }
    
        @include desktop {
            width: 700px;
            height: 100%;
        }
    }

    &__imgBox1 {
        grid-area: image1;
    }

    &__imgBox2 {
        grid-area: image2;
        margin-top: 20px;
    
        @include laptop {
            margin: 0;
        }
    
        @include desktop {
            margin: 0;
        }
    }

    &__figcaption {
        @include fontsizeLabel;
    }

    &__title {
        justify-self: center;
        grid-area: "title";
        @include fontsizeHeader;
    }

    &__content {
        grid-area: "content";
        padding: 20px;
        @include fontsize;

        @include desktop
        {
            margin-left: 20px;
            font-size: 2em;
        }
    }

    &__awdImg {
        grid-area: "award";
        height: 200px;
        justify-self: center;

        @include laptop {
            height: 250px;
            grid-column: span 2;
        }

        @include desktop {
            height: 300px;
        }
    }
}
